<template>
 <div
  class="fixed impersonate_z_index inset-0 overflow-y-auto"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
 >
  <div
   class="flex items-center justify-center impersonate_card_height_position pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
   <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

   <!-- This element is to trick the browser into centering the modal contents. -->
   <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
    >&#8203;</span
   >
   <div
    class="rounded-lg inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6"
   >
    <div>
     <!-- <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              {{ $t("impersonate.popupTitle") }}
            </h3>
          </div> -->
     <form @submit.prevent="detailId ? putRadio() : postRadio()" class="" method="POST">
      <div class="bg-white shadow sm:rounded-lg divide-y divide-gray-300">
       <div>
        <div class="-ml-4 flex justify-between items-center flex-wrap sm:flex-nowrap px-4">
         <div class="ml-4 my-3">
          <h3 class="text-lg leading-6 font-medium text-gray-900 capitalize">
           {{ $t("newRadio") }}
          </h3>
         </div>
        </div>
        <div class="space-y-6 sm:space-y-5 pb-5">
         <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
         >
          <label
           for="filename"
           class="ml-5 block text-sm font-medium text-gray-700 sm:mt-px capitalize sm:pt-2"
          >
           {{ $t("name") }}
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-2">
           <input
            type="text"
            name="filename"
            id="filename"
            required=""
            class="max-w-lg block w-full sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
            v-model="form.filename"
           />
          </div>
         </div>
         <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
         >
          <label
           for="stream"
           class="ml-5 block text-sm font-medium text-gray-700 sm:mt-px capitalize sm:pt-2"
          >
           {{ $t("stream") }}
          </label>
          <div class="mt-1 flex rounded-md shadow-sm">
           <span
            class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
            >https://</span
           >
           <input
            type="text"
            name="stream"
            id="stream"
            v-model="form.stream"
            required=""
            class="max-w-lg block w-full sm:max-w-xs sm:text-sm border-gray-300 rounded-r-md"
            placeholder="www.example.com"
           />
          </div>
         </div>
         <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
         >
          <label
           for="duration"
           class="ml-5 block text-sm font-medium text-gray-700 sm:mt-px capitalize sm:pt-2"
          >
           {{ $t("duration") }}
          </label>
          <div class="relative mt-1 rounded-md shadow-sm">
           <input
            type="number"
            min="5"
            max="20"
            name="duration"
            id="duration"
            required=""
            class="block w-full rounded-md border-gray-300 pl-7 pr-14 sm:text-sm"
            v-model="form.duration"
            placeholder="0"
           />
           <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-500 sm:text-sm" id="price-currency">min</span>
           </div>
          </div>
         </div>
         <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
         >
          <label
           for="frequency"
           class="ml-5 block text-sm font-medium text-gray-700 sm:mt-px capitalize sm:pt-2"
          >
           {{ $t("frequency") }}
          </label>
          <div class="relative mt-1 rounded-md shadow-sm">
           <input
            type="number"
            min="15"
            max="60"
            name="frequency"
            id="frequency"
            required=""
            class="block w-full rounded-md border-gray-300 pl-7 pr-14 sm:text-sm"
            v-model="form.frequency"
            placeholder="0"
           />
           <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-500 sm:text-sm" id="price-currency">min</span>
           </div>
          </div>
         </div>
         <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
         >
          <div class="px-5 text-center text-sm font-medium flex gap-1 items-center justify-between">
           <div>
            <span class="text-gray-700 capitalize">{{ $t("active") }}</span>
            -{{ " " }}
            <span class="text-gray-900 capitalize">{{ form.active ? $t("yes") : $t("no") }}</span>
           </div>
           <div>
            <SwitchGroup as="div" class="flex items-center justify-between gap-4">
             <span class="flex flex-grow flex-col">
              <!-- <SwitchLabel as="span" class="text-sm font-medium text-gray-900" passive
           >Active</SwitchLabel
          > -->
              <!-- <SwitchDescription as="span" class="text-sm text-gray-500"
              >Is it an external file?</SwitchDescription
             > -->
             </span>

             <Switch
              v-model="form.active"
              :class="[
               form.active ? 'bg-green-600' : 'bg-gray-200',
               'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2',
              ]"
             >
              <span
               aria-hidden="true"
               :class="[
                form.active ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
               ]"
              />
             </Switch>
            </SwitchGroup>
           </div>
          </div>
         </div>
        </div>
       </div>

       <div class="py-5 px-5 flex justify-end gap-2">
        <a
         href="javascript:void(0)"
         @click="this.$emit('closeForm')"
         class="rounded bg-white py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
         {{ $t("ticket.cancel") }}
        </a>
        <button
         type="submit"
         :class="`rounded inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white`"
        >
         {{ $t("ticket.send") }}
        </button>
       </div>
      </div>
     </form>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { mapGetters } from "vuex";

export default {
 props: ["detailId"],
 components: {
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
 },
 data() {
  return {
   form: {
    stream: "",
    filename: "",
    duration: null,
    frequency: null,
    active: true,
   },
  };
 },
 methods: {
  postRadio() {
   let url = `${this.$cookie.getCookie("API")}/api/v1/${
    this.hostName
   }/pbxAudioStreams?customerAccount=${this.account}&stream=${this.form.stream}&filename=${
    this.form.filename
   }&duration=${this.form.duration}&frequency=${this.form.frequency}&active=${
    this.form.active ? 1 : 0
   }`;
   axios
    .post(url)
    .then((res) => {
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("successMsg"));
     this.$emit("closeForm");
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  putRadio() {
   let url = `${this.$cookie.getCookie("API")}/api/v1/${this.hostName}/pbxAudioStreams/${
    this.detailId
   }?stream=${this.form.stream}&filename=${this.form.filename}&duration=${
    this.form.duration
   }&frequency=${this.form.frequency}&active=${this.form.active ? 1 : 0}`;
   axios
    .put(url)
    .then((res) => {
     console.log(res.data);
     this.$emit("activeBanner");
     this.$emit("success", true);
     this.$emit("successMsg", this.$t("successMsg"));
     this.$emit("closeForm");
    })
    .catch((error) => {
     this.errorHandling(error);
     this.$emit("activeBanner");
     this.$emit("success", false);
     this.$emit("successMsg", this.errorDataResponse);
    });
  },
  getData() {
   let url = `${this.$cookie.getCookie("API")}/api/v1/${this.hostName}/pbxAudioStreams/${
    this.detailId
   }?customerAccount=${this.account}`;
   axios
    .get(url)
    .then((res) => {
     this.form.stream = res.data.stream;
     this.form.filename = res.data.filename;
     this.form.duration = res.data.duration;
     this.form.frequency = res.data.frequency;
     this.form.active = res.data.active ? true : false;
    })
    .catch((error) => {
     this.errorHandling(error);
    });
  },
 },
 mounted() {
  if (this.detailId) {
   this.getData();
  }
 },
 computed: {
  ...mapGetters(["hostName", "account"]),
 },
};
</script>

<style>
.impersonate_z_index {
 z-index: 9999;
}
.impersonate_card_height_position {
 min-height: 100vh;
}
</style>
