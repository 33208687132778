<template>
 <div class="bg-white">
  <ul role="list" class="divide-y divide-gray-200">
   <li v-for="file in datas" :key="file.id">
    <a
     :class="{
      'cursor-pointer':
       userPermissions.permissions.includes('webradios.*') ||
       userPermissions.permissions.includes('webradios.edit'),
     }"
     class="block hover:bg-gray-50"
    >
     <div class="flex items-center px-4 py-4">
      <div class="min-w-0 flex-1 flex items-center">
       <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
        <div
         @click="
          userPermissions.permissions.includes('webradios.*') ||
          userPermissions.permissions.includes('webradios.edit')
           ? goToDetail(file.id)
           : ''
         "
         class="flex items-center gap-20"
        >
         <p class="text-sm font-medium truncate">
          {{ file.filename ? file.filename : file.id }}
         </p>
         <div>
          <p class="text-sm text-gray-900">
           {{ file.stream }}
          </p>
         </div>
        </div>
        <div
         @click="
          userPermissions.permissions.includes('webradios.*') ||
          userPermissions.permissions.includes('webradios.edit')
           ? goToDetail(file.id)
           : ''
         "
         class="hidden md:block"
        >
         <p class="flex items-center text-sm text-gray-500 gap-1">
          <CheckCircleIcon v-if="file.active" class="h-5 w-5 text-green-400" aria-hidden="true" />
          <XCircleIcon v-else class="h-5 w-5 text-red-400" aria-hidden="true" />
          <span class="truncate">{{ file.active ? $t("active") : $t("nonactive") }}</span>
         </p>
        </div>
        <div class="flex justify-end">
         <a
          v-show="
           userPermissions.permissions.includes('webradios.*') ||
           userPermissions.permissions.includes('webradios.delete')"
           @click="deleteFile(file.id, file.filename)"
          href="javascript:void(0)"
          :class="`p-1 shadow-sm border-2 border-black rounded-md body__table--button cursor-pointer tooltip mr-1`"
          :title="$t('delete')"
         >
          <svg
           xmlns="http://www.w3.org/2000/svg"
           class="h-4 w-4"
           viewBox="0 0 20 20"
           fill="currentColor"
          >
           <path
            fill-rule="evenodd"
            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
            clip-rule="evenodd"
           /></svg
         ></a>
        </div>
       </div>
      </div>
     </div>
    </a>
   </li>
  </ul>
 </div>
 <ask-confirmation-dialog ref="askConfirmationDialog" />
</template>

<script>
import AskConfirmationDialog from "../AskConfirmationDialog.vue";
import { UserCircleIcon } from "@heroicons/vue/solid";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/outline";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
 props: ["datas", "userPermissions"],
 components: {
  UserCircleIcon,
  Loading,
  CheckCircleIcon,
  XCircleIcon,
  AskConfirmationDialog,
 },
 data() {
  return {
   fullPage: false,
   userInfos: {},
  };
 },
 methods: {
  goToDetail(id) {
   this.$emit("openDetail", id);
  },
  async deleteFile(id, fileName) {
   let title = this.$t("deleteWebradio", { name: fileName });
   let confirmationMessage = this.$t("deleteWebradioMessage");
   let confirmButton = this.$t("delete");
   let goBack = this.$t("cancel");
   let successMsg = this.$t("elementDeletedSuccessfully", { element: this.$t("webradio") });

   const r = await this.$refs.askConfirmationDialog.show({
    title: title,
    message: confirmationMessage,
    okButton: confirmButton,
    cancelButton: goBack,
    action: "delete",
   });
   let url = `${this.$cookie.getCookie("API")}/api/v1/${
    this.hostName
   }/pbxAudioStreams/${id}?customerAccount=${this.account}`;
   if (r) {
    axios
     .delete(url)
     .then((res) => {
      if (res) {
       // this.sendMessage(successMsg);
       this.$emit("activeBanner");
       this.$emit("success", true);
       this.$emit("successMsg", successMsg);
      }
     })
     .catch(function (error) {
      this.errorHandling(error);
      this.$emit("activeBanner");
      this.$emit("success", false);
      this.$emit("successMsg", this.errorDataResponse);
     });
   }
  },
 },
 mounted() {},
 computed: {
  ...mapGetters(["hostName", "account"]),
 },
};
</script>

<style></style>
