<template>
 <!-- <PageHeading
  :button-title="$t('newStream')"
  :has-meta-data="false"
  :has-action-button="true"
  :title="$t('webRadios')"
  @open="this.showForm = !this.showForm"
 /> -->
 <PageHeading
  :button-title="$t('newStream')"
  :has-meta-data="false"
  :has-search="true"
  :has-action-button="true"
  :has-permission="
   userPermissions.permissions.includes('webradios.*') ||
   userPermissions.permissions.includes('webradios.create')
  "
  :title="$t('webRadios')"
  @open="this.showForm = !this.showForm"
  @update:search="$emit('update:search', $event)"
 />
 <div v-if="data.length > 0">
  <div class="flex flex-col">
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
     <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <div class="px-4 py-5 border-b border-gray-200 sm:px-6 bg-gray-50">
       <div class="-ml-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-2">
         <h3 class="text-lg leading-6 font-semibold text-gray-900">
          {{ $t("Streams") }} {{ hostName }}
         </h3>
        </div>
       </div>
      </div>
      <web-radios-list
       :datas="filteredData"
       :account="hostName"
       :userPermissions="userPermissions"
       @openDetail="(detailId = $event), (showForm = true)"
       @activeBanner="$emit('activeBanner', true), getData(), (detailId = undefined)"
       @successMsg="$emit('successMsg', $event)"
       @success="$emit('isSuccess', $event)"
      />
     </div>
    </div>
   </div>
  </div>
 </div>
 <div v-else>
  <EmptyState
   :title="$t('emptyStateTitle', { title: $t('webRadio') })"
   :subtitle="$t('emptyStateSubtitle', { title: $t('webRadio') })"
  />
 </div>
 <web-radio-form
  v-if="showForm"
  @closeForm="showForm = false"
  :detailId="detailId"
  @activeBanner="$emit('activeBanner', true), getData(), (detailId = undefined)"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('isSuccess', $event)"
 />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import WebRadioForm from "../../components/WebRadio/WebRadioForm.vue";
import WebRadiosList from "../../components/WebRadio/WebRadiosList.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import TablePagination from "../../components/TablePagination.vue";
import PageHeading from "../../components/PageHeading.vue";
import EmptyState from "../../components/EmptyState.vue";

const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

export default {
 props: ["id", "search"],
 components: {
  PageHeading,
  EmptyState,
  TablePagination,
  SearchFilter,
  WebRadiosList,
  WebRadioForm,
 },
 data() {
  return {
   showForm: false,
   data: [],
   detailId: undefined,
   userPermissions,
  };
 },
 methods: {
  async getData() {
   this.$emit("setLoading", true);
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/${this.hostName}/pbxAudioStreams?customerAccount=${
      this.account
     }`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );

    this.data = res.data;
   } catch (error) {
    this.errorHandling(error);
   }
   this.$emit("setLoading", false);
  },
 },
 mounted() {
  this.getData();
 },
 computed: {
  ...mapGetters(["hostName", "account"]),
  filteredData() {
   return this.data.filter((file) => {
    return file.filename.toLowerCase().includes(this.search.toLowerCase());
   });
  },
 },
};
</script>

<style></style>
